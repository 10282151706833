:root {
  /*
  @custom-media --xx-small-viewport only screen and (max-width: 320px);
  @custom-media --x-small-viewport only screen and (max-width: 416px);
  @custom-media --small-viewport only screen and (min-width: 416px) and (max-width: 580px);
  @custom-media --medium-viewport only screen and (min-width: 580px) and (max-width: 864px);
  @custom-media --large-viewport only screen and (min-width: 864px) and (max-width: 1096px);
  @custom-media --x-large-viewport only screen and (min-width: 1096px);
  @custom-media --phone (--x-small-viewport), (--small-viewport);
  @custom-media --desktop (--large-viewport), (--x-large-viewport);
  */

  --dark-color: #191919;
  --white-color: #f6f6f6;
  --gray-color: #d7d7d7;
  --pure-white: #ffffff;
}

@font-face {
  font-family: 'Amazon Ember';
  src: url('assets/Amazon-Ember-Regular.ttf');
}

@font-face {
  font-family: 'Google Sans';
  src: url('assets/GoogleSans-Regular.ttf');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('assets/proxima-nova-semibold.ttf');
}

@font-face {
  font-family: 'Skyhook Mono';
  src: url('assets/SkyhookMono.ttf');
}

@font-face {
  font-family: 'Meslo';
  src: url('assets/MesloLGL-Regular.ttf');
}

@font-face {
  font-family: 'Meslo';
  src: url('assets/MesloLGL-Bold.ttf');
  font-weight: 800;
}

body,
html,
#root {
  color: var(--dark-color);
  font-family: 'Menlo', 'Meslo', monaco, 'Courier New', Courier, monospace;
  letter-spacing: -0.5px;
  position: relative;
  width: 100%;
}

body {
  overflow-x: hidden;
}

a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a:focus,
button:focus {
  outline: 0;
}

hr {
  background-color: var(--gray-color);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
html,
p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  position: relative;
}

.transition-group {
  height: 100%;
  width: 100%;
}

.reveal-appear,
.reveal-enter {
  opacity: 0;
}

.reveal-appear-active,
.reveal-enter-active,
.reveal-enter-done {
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.reveal-exit {
  transition: none;
  opacity: 1;
}

.reveal-exit-active,
.reveal-exit-done {
  opacity: 0;
}
